import React, { useCallback, useState, useEffect } from "react";
import axios from "axios";
import { NGROK } from "../../../APIs";
import useUserStore from "../../../services/userStore";

import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableContainer,
  Box,
  Stack,
  InputLabel,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { myLocalStorage } from "../../../components/StorageHelper";
import ConfirmAlert from "../../components/ConfirmAlert";
import TenantSelection from "../Components/TenantSelection";
import EndpointsTableHead from "./components/EndpointsTableHead";
import EndpointsTableBody from "./components/EndpointsTableBody";
import { fetchTenantsData } from "../api";
import SearchField from "../logs/components/SearchField";
import useGeneralSearch from "../../../hooks/useGeneralSearch";
import { checkIfUserHassAccess } from "../../../services/Helpers";

const Endpoints = () => {
  const [tenantsList, setTenantsList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(
    myLocalStorage.getItem("latestTenant"),
  );
  const [openDeleteDeviceAlert, setOpenDeleteDeviceAlert] = useState(false);

  const { role, email, userId } = useUserStore((state) => state.user);
  const activeComputer = useUserStore((state) => state.activeComputer);

  const fetchDataProfiles = async (computerId) => {
    const response = await axios.get(
      `${NGROK}/api/${selectedTenant.tenantName}/computers/${computerId}/profiles`,
    );
    setProfiles(response.data);
    return response.data;
  };

  const getUserByEmail = async (deviceId, email) => {
    const { data: users } = await axios.get(
      `${NGROK}/api/${selectedTenant.tenantName}/computer-users/${deviceId}/users?email=${email}`,
    );

    return users.find((user) => user.email === email);
  };

  const getUserGroups = async (userId) => {
    const {
      data: { content: loginedUserGroups },
    } = await axios.get(
      `${NGROK}/api/${selectedTenant?.tenantName}/computer-user-groups/membership/${userId}`,
    );
    return loginedUserGroups;
  };

  const checkAccessByGroup = async (deviceList) => {
    const devicesWithAccessGroupId = await Promise.all(
      deviceList.map(async (device) => {
        const computerOwnerUser = await getUserByEmail(device.id, email);

        if (!computerOwnerUser) {
          const getGroupOfNotComputerOwner = async () => {
            try {
              const response = await axios.get(
                `${NGROK}/api/${selectedTenant.tenantName}/whiteswan-access?computerId=${device.id}&userId=${userId}`,
              );
              return response.data;
            } catch (error) {
              console.error(error);
            }
          };
          const whiteswanAccesGroup = await getGroupOfNotComputerOwner();

          if (checkIfUserHassAccess(whiteswanAccesGroup)) {
            return {
              ...device,
              hasAccess: true,
              whiteswanAccessGroupId: whiteswanAccesGroup.id,
              userId: userId,
              isWhiteswanAccess: true,
              accessRemainingTime: whiteswanAccesGroup.remainingTime,
            };
          }
          return {
            ...device,
            whiteswanAccessGroupId: whiteswanAccesGroup?.id,
            isWhiteswanAccess: true,
          };
        } else {
          const computerOwnerUserGroups = await getUserGroups(
            computerOwnerUser.id,
          );

          const whiteswanAccesGroup = computerOwnerUserGroups?.find(
            (group) => group.adComputerUserGroup.cn === "Whiteswan Access",
          );

          if (checkIfUserHassAccess(whiteswanAccesGroup)) {
            return {
              ...device,
              hasAccess: true,
              whiteswanAccessGroupId:
                whiteswanAccesGroup.adComputerUserGroup.id,
              userId: computerOwnerUser.id,
              accessRemainingTime: whiteswanAccesGroup.remainingTime,
            };
          } else
            return {
              ...device,
              whiteswanAccessGroupId:
                whiteswanAccesGroup?.adComputerUserGroup.id,
            };
        }
      }),
    );
    return devicesWithAccessGroupId;
  };

  const getComputers = useCallback(
    async (tenantName) => {
      try {
        const response = await axios.get(
          `${NGROK}/api/${tenantName}/computers`,
        );
        if (response.data?.length) {
          const devicesSortedById = response.data.sort((a, b) =>
            a.id > b.id ? 1 : -1,
          );

          const newDeviceList = await checkAccessByGroup(devicesSortedById);
          setDeviceList(newDeviceList);
        } else setDeviceList([]);
      } catch (error) {
        setDeviceList([]);
        console.error("computers error", error);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedTenant],
  );

  const updateDeviceProfile = async (event, device, defaultProfile) => {
    const profileName = defaultProfile ? defaultProfile : event.target.value;
    const profile = profiles?.find((el) => el.name === profileName);

    if (profile) {
      const updatedDevice = { ...device, profile: profileName };
      const updatedDevices = deviceList.map((element, index) =>
        element.id === updatedDevice.id
          ? (deviceList[index] = updatedDevice)
          : element,
      );
      setDeviceList(updatedDevices);

      await axios.put(
        `${NGROK}/api/${selectedTenant.tenantName}/computers/${device.id}/profiles/${profile.id}/update-computer`,
      );
      await axios.put(
        `${NGROK}/api/${selectedTenant.tenantName}/computers/${device.id}/profiles/${profile.id}`,
      );
    }
  };

  const updateDeviceOperationMode = async (event, device, defaultMode) => {
    const newMode = defaultMode ? defaultMode : event.target.value;
    const updatedDevice = { ...device, mode: newMode };

    const updatedDevices = deviceList.map((element, index) =>
      element.id === updatedDevice.id
        ? (deviceList[index] = updatedDevice)
        : element,
    );
    setDeviceList(updatedDevices);

    await axios.put(`${NGROK}/api/computers/${device.id}/operation-mode`, {
      mode: newMode,
      email,
    });
  };

  const updateTenantOperationMode = async (event, tenant) => {
    const newMode = event.target.value;
    const updatedTenant = { ...tenant, mode: newMode };

    setSelectedTenant(updatedTenant);

    await axios.put(`${NGROK}/api/${tenant.tenantName}/operation-mode`, {
      mode: newMode,
    });
  };

  const createGuacConnection = async (computer) => {
    try {
      const response = await axios.post(
        `${NGROK}/api/guacamole/create-connection`,
        {
          computerId: computer.id,
          selectedTime: computer.accessRemainingTime,
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getToken = async (email, computerId, userRole) => {
    try {
      return await axios.get(
        `${NGROK}/api/access/${userRole}/get-token?email=${email}&computerId=${computerId}`,
      );
    } catch (error) {
      console.error(error);
    }
  };

  const saveToken = async (email, computer, userRole) => {
    try {
      return await axios.post(`${NGROK}/api/access/${userRole}/save-token`, {
        email,
        computerId: computer.id,
        groupId: computer.whiteswanAccessGroupId,
        userId,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const connectComputer = async (computer, userRole) => {
    const connectionData = await createGuacConnection(computer);
    if (!connectionData) return;

    const inputData = `${connectionData.identifier}\0c\0postgresql`;
    const mqString = btoa(inputData);

    const response = await getToken(email, computer.id, userRole);
    const { authToken } = response?.data;

    if (authToken) {
      window.open(
        `https://${computer.guachost}/#/client/${mqString}?token=${authToken}`,
        "_blank",
      );
    } else {
      const {
        data: { authToken },
      } = await saveToken(email, computer, userRole);

      window.open(
        `https://${computer.guachost}/#/client/${mqString}?token=${authToken}`,
        "_blank",
      );
    }
  };

  const handleConnectButton = (computer) => {
    if (role === "TENANT_USER") connectComputer(computer, "user");
    else connectComputer(computer, "admin");
  };

  const toggleRecording = async (computer) => {
    const connectionData = await createGuacConnection(computer);
    if (!connectionData) return;

    const newRecordingStatus = computer.recordingPathEnabled ? false : true;

    const toggleRecordingLocally = () => {
      const updatedComputer = {
        ...computer,
        recordingPathEnabled: newRecordingStatus,
      };
      setDeviceList(
        deviceList.map((el) =>
          el.id === updatedComputer.id ? updatedComputer : el,
        ),
      );
    };
    toggleRecordingLocally();

    try {
      await axios.put(`${NGROK}/api/guacamole/update-connection`, {
        url: `https://${computer.guachost}`,
        id: connectionData.id,
        enableRecordingPath: newRecordingStatus,
        selectedTime: computer.accessRemainingTime,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const updateDevicesAfterDeleting = (id) => {
    setDeviceList((prev) => prev.filter((device) => device.id !== id));
  };

  useEffect(() => {
    if (tenantsList.length === 1) return setSelectedTenant(tenantsList[0]);

    if (tenantsList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantsList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );

      if (tenant) setSelectedTenant(tenant);
      else setSelectedTenant(tenantsList[0]);
    }
  }, [tenantsList]);

  useEffect(() => {
    if (selectedTenant) {
      myLocalStorage.setItem("latestTenant", selectedTenant); // store tenant in case of reload

      getComputers(selectedTenant?.tenantName);
      const interval = setInterval(() => {
        getComputers(selectedTenant?.tenantName);
      }, 10000);
      return () => clearInterval(interval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant]);

  const upgradeMachine = (computerId) => {
    const response = axios.post(`${NGROK}/api/agent/update`, {
      computer_id: computerId,
    });
    response
      .then(() => {
        getComputers(selectedTenant?.tenantName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (email) fetchTenantsData(email, setTenantsList);
  }, [email]);

  const allowedSearchFields = [
    "dNSHostName",
    "OperatingSystem",
    "agentVersion",
    "profile",
  ];

  const {
    searchTerm,
    filteredData: filteredDevices,
    handleSearch,
  } = useGeneralSearch(deviceList, allowedSearchFields);

  return (
    <Box width={"100%"}>
      {activeComputer ? (
        <ConfirmAlert
          updateDevicesAfterDeleting={updateDevicesAfterDeleting}
          deviceId={activeComputer.id}
          open={openDeleteDeviceAlert}
          setOpen={setOpenDeleteDeviceAlert}
          headerText={`Are you sure, you want to delete this computer - ${
            activeComputer
              ? activeComputer.cn ||
                activeComputer.dNSHostName ||
                "unknown computer"
              : ""
          }?`}
        />
      ) : null}
      <Stack spacing={5}>
        <Stack direction={"row"} spacing={4}>
          <TenantSelection
            selectedTenant={selectedTenant}
            setSelectedTenant={setSelectedTenant}
          />
          {/*  {selectedTenant ? (
            <FormControl sx={{ display: "flex", alignItems: "center" }}>
              <InputLabel id="demo-simple-select-label">Mode</InputLabel>
              <Select
                sx={{ minWidth: "100px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Operation Mode"
                value={selectedTenant.mode || " "}
                size="medium"
                onChange={(event) =>
                  updateTenantOperationMode(event, selectedTenant)
                }
              >
                <MenuItem value={"AUDITING"}>AUDITING</MenuItem>
                <MenuItem value={"ENFORCING"}>ENFORCING</MenuItem>
                <MenuItem value={"DISABLED"}>DISABLED</MenuItem>
                );
              </Select>
            </FormControl>
          ) : null} */}
          <SearchField searchTerm={searchTerm} handleSearch={handleSearch} />
        </Stack>
        {deviceList?.length ? (
          <TableContainer
            sx={{ height: "fit-content", width: "97%" }}
            component={Paper}
          >
            <Table
              aria-label="simple table"
              sx={{
                height: "fit-content",
                "& td, & th": {
                  border: "1px solid #233044",
                },
              }}
            >
              <EndpointsTableHead role={role} />
              <EndpointsTableBody
                getComputers={getComputers}
                deviceList={filteredDevices}
                updateDeviceProfile={updateDeviceProfile}
                updateDeviceOperationMode={updateDeviceOperationMode}
                handleConnectButton={handleConnectButton}
                toggleRecording={toggleRecording}
                profiles={profiles}
                setProfiles={setProfiles}
                fetchDataProfiles={fetchDataProfiles}
                selectedTenant={selectedTenant}
                setOpenDeleteDeviceAlert={setOpenDeleteDeviceAlert}
                openDeleteDeviceAlert={openDeleteDeviceAlert}
                upgradeMachine={upgradeMachine}
              />
            </Table>
          </TableContainer>
        ) : deviceList === undefined ? (
          <Box display={"flex"} p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <Box pl={5}>
            <Typography
              component={"span"}
              sx={{ fontSize: "20px", fontWeight: "500" }}
            >
              There are no computers.
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

export default Endpoints;
