import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, Box, Grid, Stack, TextField } from "@mui/material";
import AlertCharts from "./AlertCharts";
import AlertsTable from "./AlertsTable";
import AlertsDounut from "./AlertsDounut";
import RiskyAlerts from "./RiskyAlerts";
import RiskyMachines from "./RiskyMachines";
import RiskyUsers from "./RiskyUsers";
import axios from "axios";
import { NGROK } from "../../../APIs";
import { DateRangePicker, LocalizationProvider } from "@mui/lab";
import dayjs from "dayjs";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import useUserStore from "../../../services/userStore";
import { myLocalStorage } from "../../../components/StorageHelper";

const Alerts = () => {
  const userData = useUserStore((state) => state.user);
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [alertJson, setAlertJson] = useState([]);
  const [filterPagination, setFilterPagination] = useState("lw");
  const [customMode, setCustomMode] = useState(false);
  const [startEndDate, setStartEndDate] = useState([dayjs(), dayjs()]);
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [tenantList, setTenantList] = useState([]);

  /* const fetchAlertsValue = useCallback(async () => {
    try {
      const response = await axios.get(`${NGROK}/api/audit-logs`);
      const requiresAlertsValue = [];
      response.data
        .filter((dataVal) => dataVal.resourceType === "Alert")
        .map((value) => {
          let {
            computerUserEmail,
            computerName,
            eventJson,
            message,
            resourceName,
            time,
            _time,
          } = value;
          requiresAlertsValue.push({
            computerUserEmail: computerUserEmail,
            computerName: computerName,
            eventJson: eventJson,
            message: message,
            resourceName: resourceName,
            time: time !== undefined ? time : _time,
          });
        });
      setAlertJson(requiresAlertsValue);
    } catch (error) {
      console.log(error, "error fetchAlertsValue");
    }
  }, []); */

  const getInfluxData = useCallback(async () => {
    try {
      let bucket = "ondevice-auditlog-alerts";
      let url = `${NGROK}/api/influxdb/influxdblogsdata?bucketName=${bucket}&timingMode=${filterPagination}`;
      if (customMode) {
        url += `&customDateMode=${customMode}&customStartDate=${customStartDate}&customEndDate=${customEndDate}`;
      }
      url += "&limitNeeded=false";
      if (selectedTenantName !== null) {
        url += `&tenantName=${selectedTenantName}`;
      }
      const response = await axios.get(url);

      const requiresAlertsValue = [];

      response.data.map((val) => {
        let {
          ProcessUser,
          Computer,
          eventJson,
          message,
          Process,
          Time,
          sigma_results,
        } = val;

        requiresAlertsValue.push({
          computerUserEmail: ProcessUser,
          computerName: Computer,
          eventJson: eventJson,
          message: message,
          resourceName: Process,
          sigma_results: sigma_results,
          time: Time,
        });
      });
      setAlertJson(requiresAlertsValue);
    } catch (error) {
      console.log(error);
    }
  }, [
    filterPagination,
    customMode,
    customStartDate,
    customEndDate,
    selectedTenantName,
  ]);

  const handleChangeDateRange = (val) => {
    const startEndDate = [dayjs(val[0]), dayjs(val[1])];
    setStartEndDate(startEndDate);
    const startdate = new Date(val[0]);
    const enddate = new Date(val[1]);

    const formattedStartDate = `${startdate.getFullYear()}-${(
      startdate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${startdate.getDate().toString().padStart(2, "0")}`;
    const formattedEndDate = `${enddate.getFullYear()}-${(
      enddate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${enddate.getDate().toString().padStart(2, "0")}`;
    setCustomStartDate(formattedStartDate);
    setCustomEndDate(formattedEndDate);
  };
  const dataPagination = [
    { label: "Last 24 hours", value: "24Hrs" },
    { label: "Last 7 days ", value: "lw" },
    { label: "Last 30 days", value: "lm" },
    { label: "Last 90 days", value: "l3m" },
    { label: "Custom Range", value: "customDate" },
  ];
  const handleFilterPagination = (val) => {
    setCustomMode(false);
    if (val !== null) {
      let value = dataPagination
        .filter((data) => data.label === val)
        .map((val) => val.value);
      if (value[0] === "customDate") {
        setCustomMode(true);
        const currentDate = new Date();
        const nextWeekDate = new Date(currentDate);
        nextWeekDate.setDate(currentDate.getDate() - 7);
        handleChangeFilterDateRange([nextWeekDate, currentDate]);
      }
      setFilterPagination(value[0]);
    } else {
      setFilterPagination("lw");
    }
  };

  const handleChangeFilterDateRange = (val) => {
    const startEndDate = [dayjs(val[0]), dayjs(val[1])];
    setStartEndDate(startEndDate);
    const startdate = new Date(val[0]);
    const enddate = new Date(val[1]);

    const formattedStartDate = `${startdate.getFullYear()}-${(
      startdate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${startdate.getDate().toString().padStart(2, "0")}`;
    const formattedEndDate = `${enddate.getFullYear()}-${(
      enddate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${enddate.getDate().toString().padStart(2, "0")}`;
    setCustomStartDate(formattedStartDate);
    setCustomEndDate(formattedEndDate);
  };

  useEffect(() => {
    getInfluxData();
    // fetchAlertsValue();
  }, [
    filterPagination,
    customMode,
    customStartDate,
    customEndDate,
    getInfluxData,
  ]);

  useEffect(() => {
    if (tenantList.length === 1)
      return setSelectedTenantName(tenantList[0].tenantName);

    if (tenantList?.length > 1) {
      const latestTenantName =
        myLocalStorage.getItem("latestTenant")?.tenantName;

      const tenant = tenantList.find(
        (tenant) => tenant.tenantName === latestTenantName,
      );
      if (tenant) setSelectedTenantName(tenant.tenantName);
      else setSelectedTenantName(tenantList[0].tenantName);
    }
  }, [tenantList]);

  useEffect(() => {
    const fetchTenants = async () => {
      const response = await axios.get(`${NGROK}/api/get-all-domains`);
      setTenantList(response.data);
    };
    fetchTenants();
  }, [userData]);

  const handleTenantChange = (value) => {
    const tenant = tenantList.find((tenant) => tenant.tenantName === value);
    if (tenant !== undefined) {
      setSelectedTenantName(tenant.tenantName);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "15px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "15px",
          }}
        >
          {tenantList?.length > 0 ? (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={selectedTenantName ? selectedTenantName : " "}
              options={tenantList.map((tenant) => tenant.tenantName)}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Tenant List" />
              )}
              onChange={(e, value) => {
                handleTenantChange(value);
              }}
            />
          ) : null}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "15px",
          }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={dataPagination.map((value) => value.label)}
            value={
              dataPagination
                .filter((data) => data.value === filterPagination)
                .map((val) => val.label)[0]
            }
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Time Duration" />
            )}
            onChange={(e, value) => {
              handleFilterPagination(value);
            }}
          />
          {customMode ? (
            <>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  startText="Start date"
                  endText="End date"
                  maxDate={new Date()}
                  value={startEndDate}
                  onChange={handleChangeDateRange}
                  renderInput={(startProps, endProps) => (
                    <Stack direction={"row"} spacing={3}>
                      <TextField {...startProps} />
                      <TextField {...endProps} />
                    </Stack>
                  )}
                />
              </LocalizationProvider>
            </>
          ) : null}
        </Box>
      </Box>
      {alertJson !== null && (
        <Grid container gap={2} spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <AlertCharts
              alertJson={alertJson}
              mode={filterPagination}
              customDate={startEndDate}
              customMode={customMode}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3.9}>
            <AlertsDounut alertJson={alertJson} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <AlertsTable alertJson={alertJson} />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container gap={1}>
              <Grid item xs={12} sm={12} md={3.9}>
                <RiskyAlerts alertJson={alertJson} />
              </Grid>
              <Grid item xs={12} sm={12} md={3.9}>
                <RiskyUsers alertJson={alertJson} />
              </Grid>
              <Grid item xs={12} sm={12} md={3.9}>
                <RiskyMachines alertJson={alertJson} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Alerts;
